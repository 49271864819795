// eslint-disable-next-line no-underscore-dangle
(window as any).__zone_symbol__UNPATCHED_EVENTS = ['scroll', 'mousemove'];
// eslint-disable-next-line no-underscore-dangle
(window as any).__zone_symbol__PASSIVE_EVENTS = ['scroll'];
(window as any).global = window;

import 'core-js/es/global-this';
import 'core-js/es/array/at';
import 'core-js/es/object/from-entries';
import 'core-js/es/string/match-all';
import 'core-js/web/queue-microtask';
import 'intersection-observer';
import 'web-animations-js';

/* istanbul ignore if */
if (typeof ResizeObserver === 'undefined') {
  global.ResizeObserver = require('resize-observer-polyfill').default;
}

import 'zone.js';
